@use '@vismaux/ngx-vud';

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    min-height:max-content;
}

/*Removes border in top right nav menu item*/
.dropdown-toggle::after {
    content:unset!important;
}

#container {
    max-width: 100%;
    margin: 0;
    top:66.5px;
    position:absolute;
    height:100%;
}

#row {
    height:100%;
}

#col-md-2 {
    padding-left: 0!important;
    padding-right: 0!important;
}

.navbar {
    margin: 0;
}

.col-md-10 {
    background-color: #f0f2f5;
}
